import React, { Fragment } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Information from './Pages/Information';
import HomePage from './Pages/HomePage';
import PrivacyTerm from './Pages/PrivacyTerms';
import './App.css';

function App() {
  return (
    
   <>
    <BrowserRouter>
    <Routes>
    
    <Route path='/Information' element={<Information />} />
    <Route path='/' element={<HomePage />} />
    <Route path='/PrivacyTerms' element={<PrivacyTerm/>}/>
    <Route
        path="*"
        element={<Navigate to="/Error404" replace />}
    />
   

    </Routes>
    </BrowserRouter>
   </>
    
    
  
  );
}
export default App;

