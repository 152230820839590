import { isMobile } from 'react-device-detect';

const FooterNFT = () => {
  return (
    // <footer className={`d-flex ${isMobile ? 'flex-column text-center justify-content-center ' : 'justify-content-between flex-wrap'} p-4 footer-m `}>
    //   <h6><strong>FUKaff</strong></h6>
    //   <p className="text-muted" style={{ fontSize: '12px', textAlign:'center' }}>©2024 FUKaff  all rights reserved | Register Number : 1336672-4455 DELMY GROVE , PLAISANCE, (SYC)  </p>
      
    //   <p className="text-muted" style={{ fontSize: '12px' }}></p>
    // </footer>
    <footer className={`d-flex ${isMobile ? 'flex-column text-center justify-content-center ' : 'justify-content-between flex-wrap'} p-4 footer-m `}>
  <h6><strong>FUKaff</strong></h6>
  <p className="text-muted" style={{ fontSize: '14px', textAlign:'center' }}>
    ©2024 FUKaff  all rights reserved
    {isMobile ? <><br />Register Number : 1336672-4455<br />DELMY GROVE , PLAISANCE, (SYC)</> : ' | Register Number : 1336672-4455 DELMY GROVE , PLAISANCE, (SYC)'}
  </p>
  {/* <p className="text-muted" style={{ fontSize: '12px' }}>yugu</p> */}
</footer>

  );
};

export default FooterNFT;
