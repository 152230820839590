import React from "react";
import { useEffect, useState } from "react";
import istapng from '../Images/Vector.png'
import twwitpng from '../Images/Vector1.png'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import { useLocation } from "react-router-dom";
import SwipeableTopDrawer from "./bergur";

const HeaderNFT = () => {
  
 let location = useLocation();

    return (
        <header className="d-flex justify-content-center">
            <div className="total-info1">
                <div className="d-flex justify-content-between align-items-center">
        <h6 className="d-flex align-items-center"><strong className="title-size d-flex align-items-center pt-2"><a href="/" className="text-dark text-decoration-none d-flex align-items-center ">FUKaff</a></strong></h6>
        <BrowserView>
        <div>
            <ul className="d-flex gap-3 flex-wrap align-items-center mb-0">
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 pt-1 pb-1 text-secondary ${location.pathname==="/Mint" ? "text-dark":""}`} href=""><small>Mint</small> </a></li>
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 pt-1 pb-1 text-secondary ${location.pathname==="/information" ? "text-dark":""}`} href="/information"><small>Information</small></a></li>
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 pt-1 pb-1 text-secondary ${location.pathname==="/Portfolio" ? "text-dark":""}`} href=""><small>Portfolio</small></a></li>
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 pt-1 pb-1 text-secondary ${location.pathname==="/PrivacyTerms" ? "text-dark":""}`} href="/PrivacyTerms"><small>Privacy & Terms</small></a></li>
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 d-flex align-items-center `} href=""><img src={istapng} alt="" width={"18px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 d-flex align-items-center `} href=""><img src={twwitpng} alt="" width={"20px"}/></a></li>
            </ul>
        </div>
        </BrowserView>
        <MobileView>
        <SwipeableTopDrawer></SwipeableTopDrawer>

        </MobileView>
        </div>
        </div>
        
    </header>
    );
    };
    
    export default HeaderNFT;
    