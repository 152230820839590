import React from "react";
import '../App.css';
import lines from "../Images/Group 1.png"

import img1 from "../Images/Group 229.png"
import img2 from "../Images/Group 228.png"
import HeaderNFT from "../Component/Header";
import FooterNFT from "../Component/Footer";
import { isMobile } from "react-device-detect";
const Information = () => {
    
return (
  <>
  <div className='d-flex justify-content-center '>
    <div className={` ${isMobile ? "bg-mi" : "bg-di"} `}></div>
    </div>
    <HeaderNFT></HeaderNFT>
    <div className='d-flex justify-content-center'>
    <div className={` ${isMobile ? "total-info-m" : "total-info"} `}>
        <h3 className="d-flex pb-2 bold title-info"><strong> What is </strong><h3 className="color-fukaff ps-2 title-info"><strong> FUKaff? </strong></h3></h3>
        <p className="pb-5 bottomtextbox text-start">
            FUKaff is an investment fund in the field of Crypto, NFT, and Metaverse. <br />
            The initial Capital is obtained from the sale of 50,000 NFTs. <br />
            FUKaff is built based on The NFT collection where each NFT holder is a shareholder of the fund and shares in its profits and losses. <br />
            Each NFT is proof of possession and represents a single Share of the fund. In this way, the owners of FUKaff are in fact its holders.
        </p>
        <h5 className="pb-2 title-util">Number of NFTs and utilities:</h5>
        <p className="pb-5 bottomtextbox text-start">
        FUKaff NFT collection includes 50,000 items on the Solana Blockchain network, it was offered to the public at an initial price of 36 dollars, which is currently trading in NFT markets. <br />
            The holders of the FUKaff NFT collection will each associate in the fund's initial capital and the profits and losses.
        </p>
        <h5 className="pb-2 title-util">Investment and profit distribution:</h5>
        <p className="pb-2 bottomtextbox text-start">
            FUKaff invests in different sections of crypto currency and blockchain, such as buying & selling CRYPTO and NFT tokens and also investing in the field of METAVERSE. <br />
            the list of investments made with the total value of the fund and the result of the profits and losses will be displayed
            in the portfolio section of the website, this feature will be available for holders who own more than 10 NFTs, and this list is updated every 3 months. <br />
        </p>
        <p className="pb-2 bottomtextbox text-start">
            The profit distribution of the FuKaff is done in the first 6 months after the start of work and initial minting and in the next stage, it is done in 1 year when the potential profit is shared among the holders.
            At the time of announcement of potential profits payment, only the owners and Holders of NFTs will be granted as eligible for the dividend payment.
        </p>
        <p className="pb-5 bottomtextbox text-start">
            There is a possibility that at the end of each fiscal period, the fund experiences a loss, in which case the possible loss is subtracted from the
            total initial capital, and the value of the fund's profit and loss is recalculated in the next period. From the total amount of the initial sale of NFTs and the initial value of the fund, 9% accrues to the team and the rest will be invested in the fund by the team.  
        </p>
        <div className="d-flex justify-content-center pb-5">
            <img src={img1} alt="" width={"375px"}/>
        </div>
        <p className="pb-2 bottomtextbox text-start">
            From the potential profit of the fund, 9% is the team's share, 40% is to be the share of NFT holders, 11% is for the NFT market management,
            and 40% is for the return to the fund's initial capital.
        </p>
        <div className="d-flex justify-content-center pb-5">
            <img src={img2} alt="" width={"375px"}/>
        </div>
        <p className="bottomtextbox text-start">
        Therefore, after each fiscal period and obtaining possible profit, the total capital of the fund has increased, which can also increase the 
        possibility of expanding the future potential profit.
        </p>
    </div>
    </div>
    <FooterNFT></FooterNFT>
  </>
);
};

export default Information;