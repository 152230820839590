import React,{useState} from 'react';
import { SwipeableDrawer, IconButton, List, ListItem, ListItemText, Button, Box } from '@mui/material'; 
import MenuIcon from '@mui/icons-material/Menu'; 
import CloseIcon from '@mui/icons-material/Close'; 
import InstagramIcon from '@mui/icons-material/Instagram'; 
import TwitterIcon from '@mui/icons-material/Twitter'; 
import { useLocation } from 'react-router-dom'; 
import "../App.css" 
 
const SwipeableTopDrawer = () => { 
  const [open, setOpen] = useState(false); 
 
  const toggleDrawer = (openState:any) => (event:any) => { 
    if ( 
      event && 
      event.type === 'keydown' && 
      (event.key === 'Tab' || event.key === 'Shift') 
    ) { 
      return; 
    } 
    setOpen(openState); 
  }; 
  let location = useLocation(); 
 
  return ( 
    <div className=''> 
      <IconButton onClick={toggleDrawer(true)}> 
        <MenuIcon /> 
      </IconButton> 
      <SwipeableDrawer 
        anchor="top" 
        open={open} 
        onClose={toggleDrawer(false)} 
        onOpen={toggleDrawer(true)} 
      > 
        <div className="top " 
          role="presentation" 
          onClick={toggleDrawer(false)} 
          onKeyDown={toggleDrawer(false)} 
        > 
          <div className="top-title w-100 ms-2 pe-3"> 
 
            <div> 
              <h3><strong><a href="/" className="text-dark text-decoration-none d-flex align-items-center">FUKaff</a></strong></h3> 
            </div> 
            <div> 
              <button className='menu-btn pt-0 mt-0' onClick={toggleDrawer(false)}> 
                <CloseIcon /> 
              </button> 
 
            </div> 
 
 
 
 
          </div> 
 
            <a href=""><button className={`menu-btn btn text-start ${location.pathname==="/Mint" ? "button-bergur":""}`}>Mint</button></a> 
            <a href="/Information"><button className={`menu-btn btn text-start ${location.pathname==="/Information" ? "button-bergur":""}`}>Information</button></a> 
            <a href=""><button className={`menu-btn btn text-start ${location.pathname==="/Portfolio" ? "button-bergur":""}`}>Portfolio</button></a> 
            <a href=""><button className={`menu-btn btn text-start ${location.pathname==="/PrivacyTerms" ? "button-bergur":""}`}>Privacy & Terms</button></a> 
 
 
 
            <div className='d-flex justify-content-center me-2'> 
            <button className='menu-btn text-center  btn button-bergur'><InstagramIcon></InstagramIcon></button> 
            <button className='menu-btn text-center  btn button-bergur'><TwitterIcon></TwitterIcon></button> 
            </div> 
 
 
        </div> 
      </SwipeableDrawer> 
    </div> 
  ); 
}; 
 
export default SwipeableTopDrawer;