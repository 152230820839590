import React from "react";
import HeaderNFT from "../Component/Header";
import FooterNFT from "../Component/Footer";
import { isMobile } from "react-device-detect";

const PrivacyTerm = () => {

    return (
        <>

            <div className='d-flex justify-content-center '>
                <div className={` ${isMobile ? "bg-mi" : "bg-di"} `}></div>
            </div>
            <HeaderNFT></HeaderNFT>
            <div className='d-flex justify-content-center'>
                <div className={` ${isMobile ? "total-info-m" : "total-info"} `}>
                    <h3 className="d-flex pb-2 bold title-info"><strong> FUKaff </strong></h3>
                    <p className="pb-5 bottomtextbox text-start">
                        FUKaff, the website and other media related to it operate in the virtual space under the following rules and regulations, and it is necessary and harmful for you to read it carefully, because any action, including the activities of the virtual space, buying and selling of NFT collection and or any activity related to the collection is subject to these rules and the activity in the mentioned cases will constitute your acceptance and agreement with the rules, terms and conditions contained in this text.
                    </p>
                    <h3 className="d-flex pb-2 bold title-info"><strong>  General terms and conditions: </strong></h3>

                    <p className="pb-5 bottomtextbox text-start">In general, FUKaff and the collection of non-fungible tokens (NFTs), as well as the website and other social networks, can only be used by people over 18 years old, and people under 18 years old are not allowed to use it.
                        Any payment you make for FUKaff NFT collection to a third party (individual, company, platform, etc.) will be subject to independent and separate rules and regulations, and in the event of such an issue, you agree that FUKaff has no control over the payment. and transactions and will not have any responsibility towards you or a third party or possible damages that may occur as a result of transactions.
                        FUKaff website is considered its exclusive property and all codes, resources, databases, functions, designs, sound, text and graphics on the site and in general all site content and trademarks belong to FUKaff It is possible that all the mentioned items are under the ownership and control of FUKaff and are protected by the laws related to copyright and intellectual property rights and trademarks, and you have the right to make any kind of copying (in any form) including downloading, editing and using it. You will not have any social media, website and other projects. In general, FUKaff reserves all rights related to the website and content.
                        Digital Assets (NFT)
                        All FUKaff collection NFTs can be bought and sold in secondary markets or by third parties. In general, secondary transactions will be subject to the terms and conditions of the operating platform, and FUKaff, as an independent legal entity, will not have any responsibility in this regard.
                        In fact, FUKaff is solely responsible and committed as the original owner and creator of the NFT collection in the sale and initial minting and related transactions as long as there are no problems related to blockchain networks or other problems beyond the will. All damages and possible risks such as hacking are also outside FUKaff responsibility
                        The published digital assets are only in the Solana blockchain network, and other similar assets in the said network and other blockchain networks are not related to FUKaff, and FUKaff will not be responsible for the purchase and sale of these assets.
                        Special rules and regulations
                        FUKaff is aware of the risks involved in investing in the financial markets, but he will not be responsible for possible damages and losses. The basis of the possible profit or loss is the fluctuations of the crypto market, the conditions of the global economy, and other influencing factors. and it is possible that due to circumstances out of control during a certain period of time, the yield of the fund will be negative and associated with losses
                        The owners of FUKaff NFTs are considered to be the owners of NFTs, specifically, in exchange for the initial purchase and payment, and the activities of the team, such as investing and paying possible profits, include the use and usefulness of NFTs.
                        In fact, none of the NFT buyers have directly invested in the FUKaff, and they only own a collection of NFTs that any owner can use. By accepting and agreeing to the aforementioned items and being aware of the conditions and laws, buyers purchase from FUKaff NFT collection and cannot claim any possible profits or losses during the time of  ownership.
                        In fact, NFT buyers are only the owners of each item and share in the profits and losses of the fund, not in the fund's capital Also, FUKaff has no role or influence in the price of NFT collection in secondary markets, and the price is formed and determined based on supply and demand, and buyers, while accepting this issue, will not have any claims for the price of NFT collection.</p>


                    
                        <h3 className="d-flex pb-2 bold title-info"><strong>  Warranties</strong></h3>
                        <h5 className="pb-2 title-util">By using FUKaff and its website, you guarantee that:</h5>
                        <p className="pb-5 bottomtextbox text-start"><strong>1-</strong>You have legal capacity and agree to comply with these conditions <br/>
                        <strong>2-</strong>You are not considered a minor in the jurisdiction where you live<br/>
                        <strong>3-</strong>Through robots, scripts or other non-human things. You are not connected to FUKaff <br/>
                        <strong>4-</strong>You will not use FUKaff for illegal and unauthorized purposes.<br/>
                        <strong>5-</strong>Your use of FUKaff does not violate any enforceable laws and regulations.    
                        </p>
                        {/* <p className="pb-5 bottomtextbox text-start"> </p>
                        <p className="pb-5 bottomtextbox text-start"></p>
                        <p className="pb-5 bottomtextbox text-start"></p>
                        <p className="pb-5 bottomtextbox text-start"></p> */}
                        

                    
                </div>
            </div>
            <FooterNFT></FooterNFT>



        </>
    )
}
export default PrivacyTerm;
