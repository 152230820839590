import React from 'react';
import '../App.css';
import lines from "../Images/Group 1.png"
import img1 from "../Images/1.png"
import img2 from "../Images/2.png"
import img3 from "../Images/3.png"
import img4 from "../Images/4.png"
import img5 from "../Images/5.png"
import img6 from "../Images/6.png"
import shahimg from "../Images/img03.png"
import uncleimg from "../Images/img04.png"
import pierimg from "../Images/img05.png"
import twitter from "../Images/twitter.png"
import instagram from "../Images/insta.png"
import HeaderNFT from "../Component/Header";
import FooterNFT from "../Component/Footer";
import { isBrowser, isMobile } from 'react-device-detect'

const HomePage = () => {


    return (
        <>
            <div className='d-flex justify-content-center '>
                <div className={` ${isMobile ? "bg-m" : "bg-d"} `}></div>
            </div>

            <HeaderNFT></HeaderNFT>

            <div className='d-flex justify-content-center '>
                {/* <img src={lines} alt=""  className="bg-i" width="500px"/> */}
                <div className='total '>
                    <div>
                        <p className={` ${isMobile ? "maintitle" : "maintitle-d"}`}>FUKaff</p>

                        <p className={`secondtext ${isMobile ? "" : "secondtext-d"}`}>Utilites For Ever!</p>


                        {/*<div className="allbox001 ">*/}
                        {/*    <p className="stagestext-black">Stages of Rarity: NFT Collection</p>*/}
                        {/*    <p className="stagestext-ylw">Journey</p>*/}
                        {/*</div>*/}


                        <div >
                            {isMobile ? (
                                <div className={"allbox001-mob"}>
                                    <div style={{ textAlign: 'center' }}>
                                        <p className="stagestext-black-mob">Stages of Rarity: NFT</p>
                                        <div>
                                            <p className="stagestext-black-mob" style={{ display: 'inline' }}>Collection</p>
                                            <p className="stagestext-ylw-mob" style={{ display: 'inline' }}> Journey</p>
                                        </div>
                                    </div>


                                </div>
                            ) : (
                                <div className="allbox001">
                                    <p className="stagestext-black">Stages of Rarity: NFT Collection</p>
                                    <p className="stagestext-ylw">Journey</p>
                                </div>
                            )}
                        </div>




                        <div className={`d-flex justify-content-center`}>
                            <p className={` bottomtextbox  ${isMobile ? "text-len" : "w-75"}`}>Explore the journey of
                                our NFT collection, set to sell out in 4 captivating stages.<br />Secure your piece
                                before it's gone!
                            </p>
                            {/*<div style={{marginBottom:'180px'}}>.</div>*/}

                        </div>


                        <div className={`contact__card ${isMobile ? "contact__card-mob" : ""}`}>
                            <div className="contact__card-box">
                                <p className="title-info-box">Exclusive First Presale</p>
                                <p className="desc-info-box">Previous collection holders secured 50 <br /> pieces
                                    each for 1 ETH, making it a total of<br /> 25 thousand pieces.</p>
                            </div>
                            <div className="contact__card-box">
                                <p className="title-info-box">Exclusive Second Presale</p>
                                <p className="desc-info-box">Unlock whitelist entry for previous<br /> collection
                                    holders. Join the second presale<br /> for a chance to secure limited NFTs.</p>
                            </div>
                            <div className="contact__card-box">
                                <p className="title-info-box">Whitelist Access</p>
                                <p className="desc-info-box">Exclusive whitelist access for previous
                                    collection holders. Complete tasks to join
                                    Whitelist price : 36 dollars.</p>
                            </div>
                            <div className="contact__card-box">
                                <p className="title-info-box">Public Mint</p>
                                <p className="desc-info-box">Mint your unique piece for $45 each in
                                    this<br /> exclusive opportunity.<br />
                                    Don't miss your chance!</p>
                            </div>
                        </div>


                        {/*<div className={` ${isMobile ? "allbox0 d-flex flex-wrap" : "allbox0-d d-flex flex-wrap"}`}>*/}
                        {/*/!* <div className="allbox0 d-flex flex-wrap"> *!/*/}
                        {/*    <div className='d-flex'>*/}
                        {/*  <div className='b1 box '>*/}
                        {/*    <h2 className={`toptextbox ${isMobile ? "text-start ps-3":""}`}>50K</h2>*/}
                        {/*    <p className={`bottomtextbox fw-light ${isMobile ? "text-start ps-3":""}`} style={{width:"140px"}}>NFT Arts</p>*/}
                        {/*  </div>*/}
                        {/*  */}
                        {/*  <div className={`box ${isMobile ? "pe-5" : ""} `}>*/}
                        {/*    <h2 className={`toptextbox ${isMobile ? "text-end pe-3":""}`}>TBA</h2>*/}
                        {/*    <p className={`bottomtextbox fw-light ${isMobile ? "text-end pe-3":""}`} style={{width:"140px"}}>Mint Date</p>*/}
                        {/*  </div>*/}
                        {/*  </div>*/}
                        {/*  <div className='d-flex'>*/}
                        {/*  <div className={`box ${isMobile ? "b1" : ""}`}>*/}
                        {/*    <h2 className={`toptextbox ${isMobile ? "text-start ps-3":""}`}>$39</h2>*/}
                        {/*    <p className={`bottomtextbox fw-light ${isMobile ? "text-start ps-3":""}`} style={{width:"140px"}}>Mint Price</p>*/}
                        {/*  </div>*/}
                        {/*  */}
                        {/*  <div className={`box ${isMobile ? "pe-5" : ""} `}>*/}
                        {/*    <h2 className={`toptextbox ${isMobile ? "text-end pe-3":""}`}>$33</h2>*/}
                        {/*    <p className={`bottomtextbox fw-light ${isMobile ? "text-end pe-3":""}`} style={{width:"140px"}}>Whitelist</p>*/}
                        {/*  </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}


                    </div>
                    <div className="allbox01 ">
                        <p className="title-left">About</p>
                        <p className="title-right">FUKaff</p>
                    </div>
                    <div className={`d-flex justify-content-center`}>
                        <p className={` bottomtextbox  ${isMobile ? "text-len" : "w-75"}`}>FUKaff is a pioneering
                            investment fund specializing in a diverse range of financial assets. With its initial
                            capital generated from the sale of 50,000 exclusive NFTs, each NFT functions as a unit
                            representing a stake in the fund, entitling holders to a proportionate share in the fund's
                            profits and fostering a community of prosperous investors.</p>
                    </div>
                    <div className="allbox-btn">
                        <a href="/Information">
                            <button className={`button1`} formAction='/Information'>Read More</button>
                        </a>
                    </div>
                    {/* <div className="allbox01  text-center"> </div> */}
                        {/* <p className="title-left">Our Dedicated Team of Financial Visionaries</p>
                        <p className="title-dedic">Our Dedicated Team of Financial Visionaries</p>
                    </div>
                    <div className={`d-flex justify-content-center`}> 
                        {/* <p className="bottomtextbox  " */}
                        {/* <p className={` bottomtextboxf  ${isMobile ? "text-len" : "w-75"}`}>Explore the Expertise of Our
                            Diverse and Passionate Financial Team Driving FUKaff Forward.</p> */}
                    

                    
                </div>
            </div>
            <FooterNFT></FooterNFT>


        </>
    );
};

export default HomePage;